.overall_dedetail_Tab .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content {
    background-color: #fff;
}

.overall_dedetail_Tab .ant-collapse .ant-collapse-item:last-child>.ant-collapse-content {
    border-radius: 0px 0px 0px 0px;
}

.overall_dedetail_Tab .commo_chat_header {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-family: "Open Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    display: flex;
    gap: 6px;
    justify-content: flex-start;
    align-items: center;
    /* padding: 8px; */
}

.overall_dedetail_Tab .table_wrapp {
    width: 100%;
    height: 295px;
    overflow-y: scroll;
}

.overall_dedetail_Tab .table_wrapp::-webkit-scrollbar {
    width: 3px;
}

.overall_dedetail_Tab .tag_label {
    padding: 2px 4px;
    border-radius: 4px;
    background: #dacfcf;
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    color: #ffffff;
}

.overall_dedetail_Tab .ant-table.ant-table-small .ant-table-tbody>tr>td {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #63637D;
}

.overall_dedetail_Tab .ant-table-wrapper .ant-table.ant-table-small .ant-table-thead>tr>th {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    text-align: left;
    color: #232337;
}