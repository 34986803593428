.digital_assessment{
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.digital_assessment .form_wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 350px;
    overflow-y: auto;
}

.digital_assessment .form_wrapper::-webkit-scrollbar {
    width: 3px;
}

.digital_assessment .form_wrapper .row_label {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #232337;
    padding: 0 8px;
}

.digital_assessment .form_wrapper .custom_label_class {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 21.79px;
    text-align: left;
    color: #63637D;
}
.digital_assessment .heading_form_1 {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    color: #232337;
}