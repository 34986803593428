*{
    font-family: 'Open Sans';
}

.sca-dashboard .ant-card .ant-card-body {
    padding: 0;
}

.sca-dashboard {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/* .recharts-wrapper svg {
    width: 100%;
    height: 100%;
} */

.sca-dashboard .dashboard_head_des_parent {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
}

.sca-dashboard .welcome-to-tata-container {
    font-size: 20px;
    line-height: 19px;
    white-space: pre-wrap;
    text-align: left;
    /* display: inline-block; */
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.sca-dashboard .welcome-to {
    font-weight: 700;
}

.sca-dashboard .tata-group {
    color: #5a81fa;
}

.sca-dashboard .title_with_wrap_des {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.sca-dashboard .title_with_wrap_des .stay_ahead_of {
    position: relative;
    font-size: 13px;
    line-height: 19px;
    font-family: 'Open Sans';
    color: #63637d;
    text-align: left;
}

.sca-dashboard .showing_sync_ver {
    display: inline-flex;
    gap: 16px;
    align-items: center;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #232337;
    text-align: left;
}

.sca-dashboard .th_jan_2024 {
    font-size: 13px;
    line-height: 19px;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #232337;
    text-align: left;
}

.sca-dashboard .showing_sync_ver_as_on_parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sca-dashboard .insight_report {
    /* padding: 4px 12px; */
    font-size: 13px;
    line-height: 19px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #5a81fa;
    text-align: left;
    gap: 4px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.sca-dashboard .commo_chat_header {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-family: "Open Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;

}

.sca-dashboard .scan_history {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.area_chat_wrapper .area_chat_content {
    padding: 0 12px;
}
.area_chat_wrapper .area_chat_content .chart_card_wrapper {
    display: flex;
    justify-content: space-between;
}
.sca-dashboard .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: red;
}
.sca-dashboard span.chart-detail{
    font-size: 12px;
    font-weight: 600;
    color: #232337;
}

.sca-dashboard .count_content {
    color: var(--X-Biz-Styles-Typography-Sub-heading, #63637D);
    font-family: "Open Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
}

.sca-dashboard .percentage {
    color: var(--X-Biz-Styles-Typography-Sub-heading, #63637D);
    text-align: right;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sca-dashboard .month_label {
    color: var(--X-Biz-Styles-Typography-Sub-heading, #63637D);
    text-align: right;
    font-family: "Open Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sca-dashboard .area_chat_wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.sca-dashboard .instance_count {
    color: var(--X-Biz-Styles-Typography-Sub-heading, #63637D);
    text-align: right;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sca-dashboard .instance_label {
    color: var(--X-Biz-Styles-Typography-Sub-heading, #63637D);
    text-align: right;
    font-family: "Open Sans";
    font-size: 8px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.sca-dashboard .discovered_assets {
    display: flex;
    flex-direction: column;
    /* gap: 12px; */
}
.sca-dashboard .discovered_assets.package_representation {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    background: rgb(255, 255, 255);
    padding: 10px 12px 22px 12px;
    border-radius: 10px;
}

.discovered_assets .list {
    display: flex;
    /* padding: 12px; */
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
    border-radius: 8px;
    height: 310px;
    overflow: auto;
}

.discovered_assets .list::-webkit-scrollbar {
    display: none;
}

.list .head {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px
}

.list .item {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
}

.list .item_label {
    color: var(--X-Biz-Styles-Typography-Sub-heading, #63637D);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
}

.list .list_card_item {
    display: flex;
    padding: 5px;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    align-self: stretch;
    border: 1px solid var(--X-Biz-Styles-Cards-Internal-card-stroke, #EBEEF3);
    /* background: var(--X-Biz-Styles-Cards-Internal-card, #FFF); */
    background-color: #EBEEF3;
    border-radius: 10px;
}
.threat_summary_title {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 10px;
}
.components_summary_title {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 20px;
}
.license_summary_title {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 20px;
}
.vulnerability_summary_title {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 20px;
}

.assets_header .assets_title {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
}

.discovered_assets .discovered_count {
    padding: 2px 6px;
    border-radius: 4px;
    background: var(--X-Biz-Styles-Other-Primary-light, #5A81FA);
    color: #FFF;
}

.discovered_assets .view_more_or_delete {
    display: flex;
    padding-bottom: 1px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-bottom: 1px solid var(--X-Biz-Styles-Other-Primary-light, #5A81FA);

}

.discovered_assets .view_more_or_delete .view {
    color: var(--X-Biz-Styles-Other-Primary-light, #5A81FA);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sca-dashboard .over_all_risk_description {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.min-250 {
    min-width: 200px;
    max-width: 255px;
    width: 200px;

}

.sca-dashboard .company_select .ant-select-selector {
    background: transparent;
    /* border-color: transparent; */
}

.sca-dashboard .company_select .ant-select-selector:hover {
    border-color: transparent;
}

.text_ellipse {
    /* height: 20px; */
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.card-icon{
    color: #5A81FA;
    font-size: 14px;
}

.sca-dashboard .main_chart1,
.sca-dashboard .main_chart2,
.sca-dashboard .main_chart3,
.sca-dashboard .main_chart4,
.sca-dashboard .main_chart5 {
    min-height: 250px;
}

/* dropdown more css */
.view_trend_summary {
    display: flex;
    gap: 6px;
    font-size: 14px;
    line-height: 19.07px;
    text-decoration: none;
    color: #5A81FA !important;
}

.view_details {
    display: flex;
    gap: 6px;
    font-size: 14px;
    line-height: 19.07px;
    text-decoration: none;
    color: #5A81FA !important;
}

/* end */
.sca-dashboard .discovered_assets .ant-tabs .ant-tabs-nav .ant-tabs-tab{
    font-size: 11px;
    padding: 6px 20px;
}
.sca-dashboard .discovered_assets .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 0px;
}
/* make card border-radius from rechart wrapper start */
.svg_wrapper svg {
    border-radius: 0 0 8px 8px;
}

/* make card border-radius from rechart wrapper end */

/* @media (min-width: 1366px) {

    .sca-dashboard .main_chart1 {
        max-width: 255px;
        width: 100%;
    }

    .sca-dashboard .main_chart2,
    .sca-dashboard .main_chart3,
    .sca-dashboard .main_chart4,
    .sca-dashboard .main_chart5 {
        max-width: 250px;
        min-width: 200px;
        width: 200px;
    }

    .sca-dashboard .count_content {
        font-size: 35px;
    }


    .min-250 {
        min-width: 174px;
        max-width: 300px;
        width: 174px;
    }

} */


@media (min-width: 1440px) {

   

    .sca-dashboard .count_content {
        font-size: 30px;
    }

    .sca-dashboard .instance_label {
        font-size: 9px;
    }

    .sca-dashboard .instance_count {
        font-size: 22px;
    }

   


}

@media (min-width: 1600px) {

    .sca-dashboard .main_chart2,
    .sca-dashboard .main_chart3,
    .sca-dashboard .main_chart4,
    .sca-dashboard .main_chart5 {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
    }

    .sca-dashboard .main_chart1 {
        width: 100%;
        max-width: 100%;
    }

    .min-250 {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }
}