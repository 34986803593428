.scan-details .sca-scan-details{
    color: #5A81FA;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;

}
.scan-detail-side-drawer .issues{
    /* align-items: center;
    display: flex;
    justify-content: space-between; */
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 10px;
    font-weight: 600;
}
.scan_details_title{
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 10px;
}
.table-row{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
}
.table-row .first-part{
    display: flex;
}
.table-row .first-part .first-data{
    /* border: 1px solid #D2D8E9; */
    padding: 6px 12px 6px 0px;
    /* border-radius: 6px; */
    display: flex;
    font-weight: 600;
    font-size: 14px;
    color: #63637D;
}
.table-row .second-part .second-data{
    border: 1px solid transparent;
    padding: 6px 12px 6px 12px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    color: #63637D;
}
.table-row .second-part .download{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #5A81FA;
    padding: 4px 10px 4px 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    color: #5A81FA;
    margin-right: 10px;
}
.table-row .second-part .download .icon{
    color: #5A81FA;
    font-size: 20px;
    padding-right: 5px;
}
.table-row .second-part .scan-details-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #5A81FA;
    padding: 4px 10px 4px 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    background-color: #5A81FA;
    box-shadow: 0px 2px 12px 0px #3F4BFF66;
}
.table-row .second-part .scan-details-btn .addIcon{
    color: #fff;
    font-size: 20px;
    padding-right: 5px;
}
.first-data .column-icon {
    text-decoration: none;
    border-radius: 6px;
    border: 1px solid #D2D8E9;
    padding: 4px 10px;
    margin-right: 10px;
    background: #FFFFFF;
    font-size: 13px;
    font-weight: 600;
    color: #63637D;
    display: flex;
    gap: 6px;
    align-items: "center";
}
