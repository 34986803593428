.data_grid_card {
    padding: 16px;
    border-radius: 12px;
    border: 1px;
    gap: 12px;
    background: #FFFFFF;
    border: 1px solid #727B9A33;
    box-shadow: 0px 0px 40px 0px #34387814;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.data_grid_card .card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.data_grid_card .card_header .header_title {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #2B318A;
}

.data_grid_card .card_header .status {
    padding: 4px 12px;
    border-radius: 16px;
    border: 1px;
    gap: 4px;
    border: 1px solid #02B583;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #02B583;
}

.data_grid_card .summay .summary_text,
.data_grid_card .title_wrap .title_text,
.data_grid_card .identificatio_date .identificatio_date_value,
.data_grid_card .confidence .confidence_value,
.data_grid_card .status .status_value,
.data_grid_card .recommandation_div .recommandation_steps,
.data_grid_card .tags .tags_value {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #63637D;
}

.data_grid_card .summay .summary_label,
.data_grid_card .title_wrap .title_label,
.data_grid_card .identificatio_date .identificatio_date_label,
.data_grid_card .confidence .confidence_label,
.data_grid_card .status .status_label,
.data_grid_card .recommandation_div .recommandation_label,
.data_grid_card .tags .tags_label {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    letter-spacing: 0em;
    text-align: left;
    color: #9494AE;
}