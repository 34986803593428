.custom-progress-container {
    width: 100%;
}

.custom-progress-bar {
    position: relative;
    height: 3px;
    /* border: 1px solid #ccc; */
    background-color: #E3E5EB;
    border-radius: 10px;
}

.custom-progress-filled {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #5A81FA;
    border-start-end-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.custom-progress-text {
    position: absolute;
    top: 50%;
    right: 0px;
    padding: 2px 4px;
    border-radius: 6px;
    transform: translateY(-50%);
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.4px;
    text-align: center;
    color: #FFFFFF;
    background: #5A81FA;
    box-shadow: 0px 4px 10px 0px #5A81FA4D;

   
}