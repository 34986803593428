.pdf_content section {
    margin-bottom: 30px;
    /* min-height: 1200; */
}

/* .pdf_content {
    max-width: 1024px;
    margin: auto;
} */

.banner_page {
    background-image: url('../../images/pdfImage/banner_img.png');
    height: 100vh;
    width: 100%;
    margin: auto;
    background-position: center;
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    color: #fff;
    margin-bottom: 30px;
}

.banner_page .content_box {
    position: absolute;
    bottom: 15%;
    left: 10%;
    max-width: 712px;
}

.banner_page .content_box_text {
    font-size: 48px;
    font-weight: 700;
    line-height: 65.37px;
    color: #fff;
    margin: 0;
    font-family: 'Open Sans';
}

.banner_page .header_logo {
    width: 175px;
    height: 148px;
    position: absolute;
    top: 80px;
    right: 80px;
}

.banner_page .header_logo img {
    width: 100%;
}

.banner_page .flex {
    display: flex;
    color: #fff;
}

.banner_page .prepared_by_label,
.report_label,
.date_label {
    width: 175px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24.51px;
    font-family: 'Open Sans';
}

.banner_page .prepared_by_name,
.report_id,
.date_val {
    font-weight: 400;
    font-size: 18px;
    line-height: 24.51px;
    font-family: 'Open Sans';
}

/*---------------------- attact surface section ------------------- */
.attact_surface_report {
    width: 100%;
    /* height: 100vh; */
    background: #fff;
    /* padding: 0 100px; */
}

.attact_surface_report .surface_attack_hero_sec {
    /* width: 100%; */
    background-image: url('../../images/pdfImage/attack_surface_head_bg.png');
    max-height: 495px;
    height: 100%;
    padding: 60px 100px 60px 100px;
    gap: 24px;
    display: flex;
    flex-direction: column;
}

.attact_surface_report .surface_attack_hero_sec .heading {
    font-size: 48px;
    font-weight: 700;
    line-height: 65.37px;
    font-family: 'Open Sans';
    color: #fff;
    max-width: 806px;
}

.attact_surface_report .wrapper_img_table {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: flex-start;
}

.attact_surface_report .right_head_table {
    width: 693px;
    background: #030632;
    border: 1px solid #11198E;
    color: #fff;
    border-radius: 8px;
}

.attact_surface_report .header_title {
    padding: 12px 16px;
    font-size: 32px;
    font-weight: 600;
    line-height: 38.4px;
    color: #0094FF;
    font-family: 'Open Sans';
}

.right_head_table .table {
    border-collapse: collapse;
    width: 100%;
}

.right_head_table .td,
.right_head_table .header_title {
    border: 1px solid #2B318A;
    text-align: left;
    padding: 12px 16px;
    font-family: 'Open Sans';
    font-size: 20px;
    line-height: 24px;
}

.right_head_table .td:first-child,
.header_title:first-child {
    font-weight: bold;
}

.right_head_table .table .td_label {
    font-size: 18px;
    font-weight: 400;
    font-family: 'Open Sans';
    color: #FFFFFF99;
}

.attact_surface_report .surface_attact_content_wrapper {
    padding: 60px 100px;
}

.surface_attact_content_wrapper .page_head {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #232337;
    font-family: 'Open Sans';
}

/*---------------------- attact surface section end ------------------- */

/* -------------recent discover section start----------------- */
.recent_discover_issue_section .dicover_issue_content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.recent_discover_issue_section {
    background: #fff;
    padding: 60px 100px;
}

.section_common_header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    height: 90px;
}

.section_common_header .x_biz_logo {
    width: 50px;
    height: 42.29px;
}

.section_common_header .nexus_logo {
    width: 171.88px;
    height: 50px;
}

.dicover_issue_content .head_text,
.security_posture_content .head_text,
.executive_summary_content .head_text {
    font-size: 32px;
    font-weight: 600;
    line-height: 38.4px;
    color: #5A81FA;
    font-family: 'Open Sans';
    margin-bottom: 0;
}

.dicover_issue_content .head_des,
.security_posture_content .head_des,
.executive_summary_content .head_des {
    font-size: 18px;
    font-weight: 400;
    line-height: 32.4px;
    font-family: 'Open Sans';
    text-align: justify;
    color: #2C2C2C;

}

.dicover_issue_content .ant-table-wrapper .ant-table-thead>tr>th,
.table_threats .ant-table-wrapper .ant-table-thead>tr>th,
.asset_type_table .ant-table-wrapper .ant-table-thead>tr>th,
.network_security_table .ant-table-wrapper .ant-table-thead>tr>th,
.exposure_type_table .ant-table-wrapper .ant-table-thead>tr>th,
.domain_nearing_expiry_table .ant-table-wrapper .ant-table-thead>tr>th,
.certificate_nearing_expiry_table .ant-table-wrapper .ant-table-thead>tr>th,
.application_security_table .ant-table-wrapper .ant-table-thead>tr>th,
.thread_found_table .ant-table-wrapper .ant-table-thead>tr>th {
    background: #5A81FA;
    color: #fff;
}

.dicover_issue_content .ant-table-wrapper .ant-table-thead>tr>th::before,
.table_threats .ant-table-wrapper .ant-table-thead>tr>th::before,
.network_security_table .ant-table-wrapper .ant-table-thead>tr>th::before,
.asset_type_table .ant-table-wrapper .ant-table-thead>tr>th::before,
.exposure_type_table .ant-table-wrapper .ant-table-thead>tr>th::before,
.domain_nearing_expiry_table .ant-table-wrapper .ant-table-thead>tr>th::before,
.certificate_nearing_expiry_table .ant-table-wrapper .ant-table-thead>tr>th::before,
.application_security_table .ant-table-wrapper .ant-table-thead>tr>th::before,
.thread_found_table .ant-table-wrapper .ant-table-thead>tr>th::before {
    display: none;
}

/* ------------recent discover section end------------------------------------ */

/* ---------------------security posture section start-------------------------- */
.security_posture_section {
    background: #fff;
    padding: 60px 100px;
}

.security_posture_section .security_posture_content {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.column_render_text {
    display: flex;
    gap: 8px;
    align-items: center;
}

.security_posture_content .threat_text .threat_head {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: #232337;
    font-family: "Open Sans";
}

.security_posture_content .threat_text .threat_head_desc {
    font-size: 18px;
    font-weight: 400;
    line-height: 32.4px;
    color: #232337;
    font-family: "Open Sans";
}

/* ---------------------security posture section end-------------------------- */
/* ---------------------executive summary section start-------------------------- */
.executive_summary_section,
.network_security_section {
    background: #fff;
    padding: 60px 100px;
}

.executive_summary_section .executive_summary_content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.executive_summary_content .chart_head_wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.executive_summary_content .chart_head_wrap .chart_head {
    font-size: 28px;
    font-weight: 600;
    line-height: 33.6px;
    font-family: "Open Sans";
    color: #232337;
}

.executive_summary_content .chart_head_wrap .chart_description {
    font-size: 18px;
    font-weight: 400;
    line-height: 32.4px;
    font-family: "Open Sans";
    color: #232337;
}


/* ---------------------executive summary section end-------------------------- */
/* ---------------------network security section start-------------------------- */
.network_security_section .network_security_content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.network_security_content .head_wrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.network_security_content .head_wrap .heading {
    font-size: 32px;
    font-family: 'Open Sans';
    font-weight: 600;
    line-height: 38.4px;
    color: #5A81FA;
}

.network_security_content .head_wrap .sub_head,
.impact_content .header_impact {
    font-size: 28px;
    font-weight: 600;
    line-height: 33.6px;
    color: #232337;
    font-family: 'Open Sans';
}

.network_security_content .head_wrap .sub_head_desc,
.impact_content ul>li {
    font-size: 18px;
    font-weight: 400;
    line-height: 32.4px;
    color: #232337;
    font-family: 'Open Sans';
}

.recommandation {
    padding: 24px;
    border-radius: 12px;
    border: 1px dashed #5A81FA;
    background: #F4F7FF;
}

.recommandation .recommandation_header {
    display: flex;
    gap: 20px;
    align-items: center;
}

.recommandation .heading {
    font-family: 'Open Sans';
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: 'justify';
    color: #5A81FA;
}

.recommandation ul {
    padding-left: 80px;
}

/* ---------------------network security section end-------------------------- */
/* ---------------------threat found section start-------------------------- */
.threat_found_section {
    background: #fff;
    padding: 60px 100px;
}

.threat_found_section .threat_fount_content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.threat_fount_content .header_content_wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.threat_fount_content .header_content_wrap .heading {
    font-family: 'Open Sans';
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;

}

.threat_fount_content .header_content_wrap .heading_para {
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: justified;
    margin-bottom: 0;
}

.threat_fount_content .thread_found_chart {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

/* ---------------------threat found section end-------------------------- */
/* ---------------------informatio exposure section start-------------------------- */
.information_exposure_section {
    background: #fff;
    padding: 60px 100px;
}

.information_exposure_section .information_exposure_content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.information_exposure_content .header_content_wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.information_exposure_content .header_content_wrap .heading_para {
    font-family: 'Open Sans';
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
}

.information_exposure_content .header_content_wrap .heading_para {
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: 'justify';
}

.information_exposure_content .certificate_nearing_expirys_heading,
.information_exposure_content .domain_nearing_expiry_heading {
    font-family: 'Open Sans';
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;

}

/* ---------------------informatio exposure section end-------------------------- */
/* ---------------------security rating section start-------------------------- */
.security_rating_section {
    background: #fff;
    padding: 60px 100px;
}

.security_rating_section .security_rating_content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.security_rating_content .header_content_wrap .heading {
    font-family: 'Open Sans';
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: 'justify';
    color: #5A81FA;
}

.security_rating_content .header_content_wrap .heading_para {
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: 'justify';
    margin-bottom: 0;
}

.security_rating_content .security_rating_chart {
    display: flex;
    justify-content: center;
}

.security_rating_content .chart_heading {
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #232337;
}

.security_rating_content .label_indication_div {
    background: #F0F3FF;
    padding: 12px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.security_rating_content .label_indication_div .critical_label {
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #FF3043;
}

.security_rating_content .label_indication_div .normal_label {
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #21C81E;
}

/* ---------------------security rating section end-------------------------- */
/* ---------------------summary section start-------------------------- */
.summary_section {
    background: #fff;
    padding: 60px 100px;
}

.summary_section .summary_section_content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.summary_section_content .summary_heading {
    font-family: 'Open Sans';
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: justified;
    color: #5A81FA;
}

.summary_section_content .heading_para {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: 'justify';
    margin-bottom: 0;
}

.summary_section_content .application_security_table_wrap {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.summary_section_content .application_sec_table_header_wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.summary_section_content .application_sec_table_header_wrap .table_header {
    font-family: 'Open Sans';
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
}

.summary_section_content .application_sec_table_header_wrap .subHeader {
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: 'justify';
    margin-bottom: 0;
}

/* ---------------------summary section end-------------------------- */


/*---------------------- assets group details section start ------------------- */
.assets_details {
    width: 100%;
    /* height: 100vh; */
    background: #fff;
    /* padding: 0 100px; */
}

.assets_details .surface_attack_hero_sec {
    /* width: 100%; */
    background-image: url('../../images/pdfImage/attack_surface_head_bg.png');
    min-height: 495px;
    height: 100%;
    padding: 60px 100px 60px 100px;
    gap: 24px;
    display: flex;
    flex-direction: column;
}

.assets_details .assets_details_section_content {
    padding: 60px 100px;
}

.assets_details .surface_attack_hero_sec .heading {
    font-size: 48px;
    font-weight: 700;
    line-height: 65.37px;
    font-family: 'Open Sans';
    color: #fff;
    max-width: 806px;
}

.assets_details .wrapper_img_table {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: flex-start;
}

.assets_details .right_head_table {
    width: 693px;
    background: #030632;
    border: 1px solid #11198E;
    color: #fff;
    border-radius: 8px;
}

.assets_details .header_title {
    padding: 12px 16px;
    font-size: 32px;
    font-weight: 600;
    line-height: 38.4px;
    color: #0094FF;
    font-family: 'Open Sans';
}

.right_head_table .table {
    border-collapse: collapse;
    width: 100%;
}

.right_head_table .td,
.right_head_table .header_title {
    border: 1px solid #2B318A;
    text-align: left;
    padding: 12px 16px;
    font-family: 'Open Sans';
    font-size: 20px;
    line-height: 24px;
}

.right_head_table .td:first-child,
.header_title:first-child {
    font-weight: bold;
}

.right_head_table .table .td_label {
    font-size: 18px;
    font-weight: 400;
    font-family: 'Open Sans';
    color: #FFFFFF99;
}

.assets_details .surface_attact_content_wrapper {
    padding: 60px 100px;
}

.assets_details .execution_summary_content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.progress_domain_health .ant-progress .ant-progress-inner {
    border-radius: 0px;
}

.progress_domain_health .ant-progress .ant-progress-bg {
    border-radius: 0px 100px 100px 0px
}

.progress_domain_health .progre_label {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #E10505;
    margin-bottom: 6px;
    margin-left: 30px;
}

.progress_domain_health .overall_text_label {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    color: #63637D;
}

.table_content .table_header {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #232337;
}

/*---------------------- assets group details section end ------------------- */

/* ----------------------domain health section start-------------------------  */
.domain_health_section {
    background: #fff;
    padding: 60px 100px;
}

.domain_health_section .domain_health_content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.domain_health_content .heading {
    font-family: Open Sans;
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #5A81FA;
}

.domain_health_content .heading_para {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: justified;
    color: #2C2C2C;
}

.card_type_wrapper .domain_health_card_btn,
.card_type_wrapper .domain_health_card_btn:hover {
    padding: 8px 16px 8px 16px;
    border-radius: 22px;
    gap: 10px;
    background: #02B583;
    box-shadow: 0px 4px 12px 0px #00D2984D;
    color: #fff;
    text-decoration: none;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;

}

.dns_progration .card_body_text {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: #2C2C2C;
}

.blaklisted_status .label_text,
.dns_record .label_text {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #63637D;
    padding: 10px 16px 10px 0px;
}

.blaklisted_status .label_value,
.dns_record .label_value {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #232337;
    padding: 10px 16px 10px 0px;

}

/* ----------------------domain health section end-------------------------  */