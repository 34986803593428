.xbitz_header .header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.xbitz_header .header_right_panel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    /* flex: 1 0 0; */
}

.xbitz_header .profile_img_div {
    display: flex;
    width: 36px;
    height: 36px;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 16px;
}

.xbitz_header .profile_img {
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
}

/* profile modal */
.profile_modal {
    width: 300px;
    max-width: 400px;
    /* transform-origin: 886px -75px; */
    position: fixed;
    top: 60px;
    right: 15px;
    left: auto;
}

.notification_modal {
    width: 300px;
    max-width: 400px;
    /* transform-origin: 886px -75px; */
    position: fixed;
    top: 60px;
    right: 40px;
    left: auto;
}

.profile_modal::before {
    content: '';
    position: absolute;
    top: -30px;
    left: auto;
    right: 30px;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-bottom-color: #fff;
    z-index: 100;
}

.profile_modal .ant-modal-content {
    border-radius: 18px;
    padding: 12px;
}

.profile_modal .profile_card_content .user_info {
    background: #F1F5FF;
    border-radius: 14px 14px 0px 0px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.profile_modal .profile_card_content .user_info .user_name {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.51px;
    color: #232337;
}

.profile_modal .profile_card_content .user_info .user_role,
.profile_modal .profile_card_content .user_info .login_time {
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    color: #9494AE;
    margin: 0;
}

.profile_modal .profile_card_content .change_password {
    padding: 12px 16px;
    border-bottom: 1px solid #727B9A33
}

.profile_modal .profile_card_content .common_style {
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    color: #232337;
}

.profile_modal .profile_card_content .settings {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 12px 16px;
}

.profile_modal .profile_card_content .setting_wrap {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
}

.profile_modal .profile_card_content .select_dashboard .select_dash_label {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 20.43px;
    color: #63637D;
    margin-bottom: 8px;
}

.profile_modal .profile_card_content .help {
    padding: 12px 16px;
}

.profile_modal .profile_card_content .log_out button {
    width: 100%;
    border: none;
    background: transparent;
    border-radius: 0;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
}

.profile_modal .profile_card_content .log_out {
    background: #F1F5FF;
    border-radius: 0px 0px 14px 14px;
    padding: 12px 16px;
}


/* notification modal */
.xbitz_header .modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #000;
    --bs-backdrop-opacity: 0.5;
    position: absolute;
    top: 70px;
    right: 0;
    left: auto;
    z-index: var(--bs-backdrop-zindex);
    width: 400px;
    height: 100vh;
    background-color: #fffefe;
    transition: all 0.3s;
    border-radius: 18px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.notification_modal::before {
    content: '';
    position: absolute;
    top: -30px;
    left: auto;
    right: 114px;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-bottom-color: #fff;
    z-index: 100;
}

.xbitz_header .header_wrapper .dashboard_name_title {
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 700;
    line-height: 19px;
    text-align: left;
    color: #232337;
}
.xbitz_header .header_wrapper .header_icom_wrap{
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}