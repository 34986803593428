
 .welcome-to-tata-container {
    font-size: 20px;
    line-height: 19px;
    white-space: pre-wrap;
    text-align: left;
    /* display: inline-block; */
    display: flex;
    gap: 5px;
    flex-direction: column;
}
.welcome-to {
    /* font-weight: 600; */
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;

}
.tata-group {
    color: #5a81fa;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}
.title_with_wrap_des {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.title_with_wrap_des .stay_ahead_of {
    position: relative;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    font-family: 'Open Sans';
    color: #63637d;
    text-align: left;
}
.showing_sync_ver {
    display: inline-flex;
    gap: 16px;
    align-items: center;
    font-size: 11px;
    line-height: 20px;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #232337;
    text-align: left;
}
.th_jan_2024 {
    font-size: 13px;
    line-height: 19px;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #232337;
    text-align: left;
}

.showing_sync_ver_as_on_parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.insight_report {
    /* padding: 4px 12px; */
    font-size: 13px;
    line-height: 19px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #5a81fa;
    text-align: left;
    gap: 4px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

