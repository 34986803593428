.identification{
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 350px;
        width: 100%;
        overflow-y: scroll;
}
.identification::-webkit-scrollbar{
    width: 3px;
}
.identification .selectable_btn {
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    gap: 16px;
    border-radius: 12px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    border: 1px solid #727B9A33;
    box-shadow: 0px 0px 40px 0px #34387814;
    /* color: #FFF; */
    width: 100%;
    background: #FFF;
}

.selectable_btn .btn_img_wrap {
    display: flex;
    gap: 16px;
    align-items: center;
}
.selectable_btn .btn_inner_text {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;
    color: #5A81FA;
}

.identification .heading_form_1 {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    color: #232337;
}

.identification .vendor_policy_label {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: #232337;
}
.identification .form_wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.identification .form_wrapper .custom_label_class {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 21.79px;
    text-align: left;
    color: #63637D;
}