.project_chart_wrapper .ant-table-wrapper .ant-table-thead>tr>th {
    padding: 10px 12px;
    font-size: 13px;
    background: #F3F3FE;
}

.project_chart_wrapper .ant-table-wrapper .ant-table-tbody>tr>td {
    /* padding: 6px 8px; */
    font-size: 14px;
}

.project_chart_wrapper .table_btn_severity {
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--X-Biz-Styles-Status-High, #F50000);
    color: #F50000;

}
.summary_chart tr:hover {
  padding: 0; /* Set padding to 0 on hover */
}

.project_chart_wrapper .threat_summary_title {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 10px;
}

.summary_chart .ant-pagination-prev, .ant-pagination-next, .ant-pagination-prev a, .ant-pagination-next a {
  color: #9494AE !important;
}
.side-drawer .ant-drawer-body{
  padding: 0px 24px 24px;
}
.side-drawer .ant-tabs .ant-tabs-nav .ant-tabs-tab {
  font-size: 13px;
  font-weight: 600;
}

.popup-slide-up {
    animation: slideUp 0.5s forwards;
  }
  
  .popup-slide-down {
    animation: slideDown 0.5s forwards;
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100%);
    }
  }