.label_value_pair_table_view .ant-table-thead {
    display: none;
}

.label_value_pair_table_view .label_column_text {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #63637D;
}

.label_value_pair_table_view .value_column_text {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #232337;
}

.label_value_pair_table_view .submited_status {
    padding: 6px 10px;
    font-family: "Open Sans";
    font-size: 12px;
    line-height: 16px;
    color: "#FFFFFF";
    background-color: "red";
    border-radius: 18px;
}


/* another opion */
.label_value_pair_table_view .key_val_pair {
    width: 100%;
    display: inline-flex;
    border-bottom: 1px solid #727B9A33;
    padding: 8px;
}

.label_value_pair_table_view .status_or_label_col {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #63637D;
    width: 30%;
}

.label_value_pair_table_view .value_col {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #232337;
}

.label_value_pair_table_view .status_btn_label {
    background: #FE9706;
    box-shadow: 0px 2px 12px 0px #FFB85466;
    padding: 4px 14px;
    gap: 10px;
    border-radius: 18px;
    opacity: 0px;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #FFFFFF;
}