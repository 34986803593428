.statistic_card {
    padding: 10px;
    border-radius: 8px;
    border: 1px;
    gap: 20px;
    background: #FFFFFF;
    border: 1px solid #EBEEF3;
    box-shadow: 0px 0px 40px 0px #34387814;
    /* min-width: 100%; */
}

.statistic_card .title_label {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #63637D;
}

.statistic_card .content_wrapper {
    display: flex;
    justify-content: space-between;
    /* gap: 30px; */
    align-items: center;
    overflow: hidden;
}

.statistic_card .statics {
    display: flex;
    flex-direction: column;
    /* width: 160px; */
}

.statistic_card .count_number {
    font-family: Open Sans;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: #2B318A;
}

.statistic_card .statics_title {
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}