.card_option_1 .ant-card .ant-card-head,
.card_option_1 .ant-card .ant-card-body {
    padding: 12px 16px;
}

.card_option_1 .grade_wrapper {
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.card_option_1 .grade_wrapper .grade {
    border-radius: 8px;
    color: #fff;
    background: #fab5b5;
    min-width: 30px;
    text-align: center;
    padding: 0px 8px 0px 8px
}

.card_option_1 .grade_wrapper .grade_val {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;

}

.card_option_1 .total_footer .total_text {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #9494AE;
}

.card_option_1 .total_footer .total_value {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color: #232337;
}


/* --------------------card optio 2 css start------------------------------------ */
.card_option_2 .ant-card .ant-card-head,
.card_option_2 .ant-card .ant-card-body {
    padding: 12px 16px;
}

.card_option_2 .grade_wrapper {
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.card_option_2 .grade_wrapper .grade {
    border-radius: 8px;
    /* color: #fff; */
    /* background: #fab5b5; */
    min-width: 30px;
    text-align: center;
    padding: 0px 8px 0px 8px
}

.card_option_2 .grade_wrapper .grade_val {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;

}

.card_option_2 .total_footer .total_text {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #9494AE;
}

.card_option_2 .total_footer .total_value {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color: #232337;
}

/* --------------------card optio 2 css end------------------------------------ */


/* --------------------card optio 3 css start------------------------------------ */
.card_option_3 .ant-card .ant-card-head,
.card_option_3 .ant-card .ant-card-body {
    padding: 8px 16px;
}

.card_option_3 .grade_wrapper {
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.card_option_3 .grade_wrapper .grade {
    border-radius: 8px;
    color: #fff;
    /* background: #fab5b5; */
    min-width: 30px;
    text-align: center;
    padding: 0px 8px 0px 8px
}

.card_option_3 .grade_wrapper .grade_val {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;

}

.card_option_3 .total_footer .total_text {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #9494AE;
}

.card_option_3 .total_footer .total_value {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color: #232337;
}

/* --------------------card optio 3 css end------------------------------------ */


/* --------------------card optio 4 css start------------------------------------ */
.card_option_4 .ant-card .ant-card-head,
.card_option_4 .ant-card .ant-card-body {
    padding: 8px 16px;
}

.card_option_4 .grade_wrapper {
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.card_option_4 .grade_wrapper .grade {
    border-radius: 8px;
    color: #fff;
    /* background: #fab5b5; */
    min-width: 30px;
    text-align: center;
    padding: 0px 8px 0px 8px
}

.card_option_4 .grade_wrapper .scan_url {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color: #5A81FA;

}

.card_option_4 .total_footer .total_text {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #9494AE;
}

.card_option_4 .total_footer .total_value {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color: #232337;
}

/* --------------------card optio 4 css end------------------------------------ */