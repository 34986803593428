/* .common_bottom_drawer {
    position: fixed;
    bottom: 0;
    left: 0;
} */

.common_bottom_drawer .ant-drawer-content-wrapper {
    height: 100vh;
    overflow-x: hidden;
    /* Add this line */
}

.common_bottom_drawer .ant-drawer-body {
    height: calc(100% - 30px);
    overflow-y: auto;
}

.common_bottom_drawer .ant-drawer-header-title {
    display: flex;
    flex: 1;
    align-items: center;
    min-width: 0;
    min-height: 0;
    flex-direction: row-reverse;
    color: #fff;
}

.common_bottom_drawer .ant-drawer-title {
    color: #fff;
}

.common_bottom_drawer .ant-drawer-header {
    background: #2B318A;
    color: #fff;
}

.common_bottom_drawer .children_wrapper_container {
    /* height: calc(100% - 30px); */
    height: 100%;
    overflow-y: auto;
}

.common_bottom_drawer .children_wrapper_container::-webkit-scrollbar {
    display: none;
}