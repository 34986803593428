.login_page_wrapper {
    background: #ECECF6;

}

.left_content_wrapper {
    background: linear-gradient(180deg, #2B318A 0%, #151958 100%);
    color: #fff;
    height: 100vh;
}

.content_container {
    width: 500px;
    height: 600px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 16px;
}

.content_container .caousel_wrapper {
    max-width: 500px;
    /* width: 100%;
    height: 600px;
    overflow: auto; */
}

.carousel_slider {
    width: 100%;
    height: 550px;
    overflow: auto;
}

.carousel_slider::-webkit-scrollbar {
    display: none;
}

.left_content_wrapper .text_wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    color: #fff;

}

.left_content_wrapper .header_text {
    font-size: 32px;
    font-weight: 500;
    font-style: normal;
    line-height: 37.5px;
    color: #fff;
}

.left_content_wrapper .description_text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-style: normal;
}

.custom_dots_btn {
    bottom: -10px !important;
    justify-content: end !important;
}

.custom_dots_btn li,
li.slick-active {
    width: 10px !important;
}

.custom_dots_btn button {
    height: 10px !important;
    border-radius: 50% !important;
}

.left_content_wrapper .know_more,
.know_more:hover {
    background: #fff;
    color: black;
    /* width: 105px; */
    /* height: 43px; */
    padding: 8px;
    border-radius: 8px;
    border: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 12px 0px #B000064D;
    font-size: 16px;
    line-height: 18.75px;
    font-weight: 500;
}


/* -------------------------------login card css--------------- */
.login_container {
    height: 100vh;
}

.login_container_card {
    width: 400px;
    height: 450px;
    border-radius: 16px;
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0px 0px 40px 0px #34387814;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.login_container_card .login_content_wrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.login_container_card .logo img {
    width: 190px;
    height: 50px
}

.login_container_card .header_des .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    color: #2B318A;
}

.login_container_card .header_des .des {
    color: #63637D;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
}

.login_container_card .login_form_content .login_form_forgot {
    text-decoration: none;
    color: #9494AE;
    font-size: 10px;
    line-height: 11.72px;
    font-weight: 500;
    text-align: right;
}