.tprm_onboarding .header_title {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #232337;
}

.tprm_onboarding .sync_data {
    display: flex;
    gap: 4px;
    color: #5A81FA;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    text-align: left;
    text-decoration: none;
}
.tprm_onboarding .card_wrapper{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.tprm_onboarding .column-icon{
    text-decoration: none;
      border-radius: 6px;
      border: 1px solid rgb(210, 216, 233);
      padding: 4px 10px;
      background: rgb(255, 255, 255);
      font-size: 12px;
      font-weight: 600;
      color: rgb(99, 99, 125);
      display: flex;
      gap: 6px;
      align-items: center;
  }