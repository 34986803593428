.onboarding_preview .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content {
    background-color: #fff;
}

.onboarding_preview {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
    height: 391px;
    overflow-y: scroll;
    padding: 4px;
}

.onboarding_preview::-webkit-scrollbar {
    width: 3px;
}

.onboarding_preview .heading_form_1 {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    color: #232337;
}

.onboarding_preview .policy_definition {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    padding: 10px 12px;
    border-bottom: 1px solid #dcdcdc;
}

.onboarding_preview .selected_policy_definition_text {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    text-align: left;
    color: #9494AE;
}

.form_checkbox .ant-checkbox .ant-checkbox-inner {
    border-radius: 0;
}

.form_checkbox .ant-checkbox-checked .ant-checkbox-inner,.form_checkbox .ant-checkbox-checked .ant-checkbox-inner:hover {
    background-color: #CDCDD4;
    border-color: #CDCDD4;
}

.form_checkbox {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 600;
    line-height: 21.79px;
    text-align: left;
    color: #9494AE;
}

.onboarding_preview .form_wrapper .row_label {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #232337;
    padding: 0 8px;
}

.onboarding_preview .form_wrapper .custom_label_class {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 21.79px;
    text-align: left;
    color: #63637D;
}