.dashboard_option_modal {
    width: 300px;
    max-width: 400px;
    /* transform-origin: 886px -75px; */
    position: fixed;
    top: 60px;
    right: 10px;
    left: auto;
}

.dashboard_option_modal::before {
    content: '';
    position: absolute;
    top: -30px;
    left: auto;
    right: 192px;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-bottom-color: #fff;
    z-index: 100;
}

.dashboard_option_modal .ant-modal-content {
    padding: 12px;

}

.dashboard_option_modal .dash_option_wrap {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.dashboard_option_modal .dash_option_card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px 8px;
    border-radius: 12px;
    filter: grayscale(1);

}

.dashboard_option_modal .dash_option_card .dash_option_text {
    max-width: 153px;
    max-height: 38px;
    text-align: center;
    font-size: 14xp;
    line-height: 19.07px;
    font-weight: 600;
    /* color: #232337; */
}

.dashboard_option_modal .dash_option_card:hover {
    filter: brightness(1);
    color: #5A81FA;
}

.dashboard_option_modal .active_dashboard {
    border: 1px solid #5A81FA;
    filter: brightness(1);
    color: #5A81FA;
}