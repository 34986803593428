
.affected-para{
    color: #63637D;
    font-size: 13px;
    font-weight: 400;
}
.solution-para{
    color: #63637D;
    font-size: 13px;
    font-weight: 400;
}
.solution-heading{
    font-weight: 600;
    font-size: 14px;
    color: #232337;
}
.affected-custom-table .ant-table {
   
}

.affected-custom-table .ant-table-thead th {
    font-weight: 600 !important;
    font-size: 13px !important;
    color: #63637D !important;
}


.affected-custom-table .ant-table-tbody td {
    font-weight: 400 !important;
    font-size: 13px !important;
    color: #63637D !important;
}
.dropdown-container {
    display: flex;
    flex-direction: row;
    align-items: end;
  }
  
  .dropdown-column {
    margin-right: 20px;
  }
  .dropdown-column .solution-dropdown{
    font-weight: 400;
    font-size: 13px;
    color: #232337;
  }
  .reference-para{
    font-style: italic;
    color: #E10505;
    font-size: 13px;
    font-weight: 400;
  }
  .reference-detail{
    display: flex; 
    align-items: center;   
  }
  .reference-detail .redirect{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    background-color: #F3F3FE;
    color: #3F46AB;
    border-radius: 20px;
    padding: 10px 12px 10px 12px;
    font-weight: 600;
    font-size: 13px;
    margin-top: 10px;
  }
  .reference-detail .redirect .material-icons{
    color: #3F46AB;
    font-size: 18px;
    padding-right: 5px;
  }
  .reference-detail .chips{
    border: 1px solid #DCDDE7;
    background-color: transparent;
    margin-left: 10px;
    padding: 4px 16px 4px 16px;
    border-radius: 15px;
    margin-top: 5px;
    font-size: 12px;
  }
