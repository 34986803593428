.tabs_with_btn .ant-tabs-nav::before {
    border-bottom: 0;
}

.tabs_with_btn .extra_btn {
    display: flex;
    gap: 10px;
}
.tabs_with_btn .extra_btn Button{
   font-size: 13px;
   font-weight: 600;
}

  .ant-tabs-nav-list{
    color: #9494AE; 
  }