.question_details .question_row{
    border-bottom: 1px solid #727B9A33;
}
.question_details .question{
padding: 10px 16px 12px 0px;
gap: 8px;
border: 0px 0px 1px 0px;
opacity: 0px;
background: #FFFFFF;
/* border-bottom: 1px solid #727B9A33; */
font-family: Open Sans;
font-size: 13px;
font-weight: 600;
line-height: 19px;
text-align: left;
color: #63637D;
}
.question_details .answer_respect_question{
padding: 10px 16px 12px 16px;
gap: 8px;
opacity: 0px;
background: #FFFFFF;
font-family: Open Sans;
font-size: 13px;
font-weight: 600;
line-height: 19.07px;
text-align: left;
color: #232337;

}