.category_wise_issues {
    background: #f8f8f8;
    height: 100%;
    padding: 16px 0px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid var(--X-Biz-Styles-Cards-Internal-card-stroke, #EBEEF3)
}

.category_wise_issues .category_wise_issues_header {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #232337;
}

.category_wise_issues .content_wrapper {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    /* padding: 20px; */
}

.category_wise_issues .content_wrapper .wrapper_issue {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 85px;
}

.category_wise_issues .content_wrapper .wrapper_issue .issue_values {
    font-family: 'Open Sans';
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #FF9D01;
}

.category_wise_issues .content_wrapper .wrapper_issue .issue_category {
    font-family: 'Open Sans';
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #63637D;
    text-transform: uppercase;
    overflow-wrap: break-word;

}