.mobile_grid .header_mobile_card .heading_mobile {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;

}

.mobile_grid .card_body_wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.mobile_grid .header_title {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #232337;
}
.mobile_grid .sync_data {
    display: flex;
    gap: 4px;
    color: #5A81FA;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    text-align: left;
    text-decoration: none;
}