.dynamic_table .ant-table-wrapper .ant-table-thead th.ant-table-column-sort,
.dynamic_table .ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: none;
}

.dynamic_table .ant-table-wrapper .ant-table-thead>tr>th {
    background: #cccaca;
}

/* .dynamic_form_modal .ant-modal {
    width: 100%;
} */

.dynamic_form_modal {
    width: calc(100% - 210px);
    position: fixed;
    top: 0;
    right: 0;
}

.dynamic_form_modal .ant-modal-content {
    height: 100vh;
    overflow: auto;
}

.dynamic_form_modal .ant-modal-content::-webkit-scrollbar {
    display: none;
}

.ant-modal .ant-modal-close {
    position: absolute;
    top: 28px;
    inset-inline-end: 17px;
    z-index: 1010;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 600;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border-radius: 4px;
    width: 22px;
    height: 22px;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.2s, background-color 0.2s;
}

.dy_namic_table  .ant-table-thead >tr>th {
    background: #F3F3FE;
    text-transform: capitalize;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #63637D;
}