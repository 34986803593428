.container-fluid .ant-card-body{
    padding: 10px 5px 7px 7px;
}
/* .ant-col.ant-col-xs-24.ant-col-sm-7.ant-col-md-7.ant-col-lg-7.css-dev-only-do-not-override-1drr2mu{
    padding: 0px;
}  */
.history-card{
    width: 400px;
    cursor: default;
}
.card-details{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 12px;
    color: #63637D;
}
.card-details .span-details{
    font-weight: 600;
    font-size: 12px;
    color: #232337;
    margin-left: 2px;
}
::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the thumb */
    border-radius: 5px; /* Radius of the thumb */
  }
  
  ::-webkit-scrollbar-track {
    background-color: #eee; /* Color of the track */
  }