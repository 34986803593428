/* .time_line_card .ant-card-body {
    display: flex;
    flex-direction: column;
    gap: 12px;
} */

.time_line .timeline_card_header .status_wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
}

.time_line .timeline_card_header .status_btn {
    padding: 4px 14px 4px 14px;
    border-radius: 18px;
    gap: 10px;
    background: #FE9706;
    /* box-shadow: 0px 2px 12px 0px #FFB85466; */
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
}

.time_line .timeline_card_header .by_notation {


    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    font-style: normal;
    color: #9494AE;
}

.time_line .timeline_card_header .by_notation_name {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #63637D;
    display: inline-flex;
    gap: 4px;
}

.time_line_card .time_line_content_body {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.time_line .time_line_content_body .remark {
    display: flex;
    flex-direction: column;
}

.time_line .time_line_content_body .remark_text {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #9494AE;
}

.time_line .time_line_content_body .remark_label {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #63637D;
}

.time_line_card .report_url_attachments {
    display: flex;
    gap: 24px;
}

.time_line_card .report_url_attachments .auto_generated_url,
.time_line_card .report_url_attachments .attachments {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.report_url_attachments .url,
.attachments .attachment_file {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #5A81FA;
}

.auto_generated_url .url_label,
.attachments .attachment_label {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #63637D;
}

.time_line_card .timeline_date {
    font-family: Open Sans;
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color: #63637D;
}

.time_line_card .confirm_activity_btn,
.time_line_card .confirm_activity_btn:hover {
    padding: 6px 14px;
    border-radius: 4px;
    gap: 4px;
    background: #5A81FA;
    box-shadow: 0px 2px 12px 0px #3F4BFF66;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    border: none;

}