/* common class reusable */
.decoration_none {
    text-decoration: none;
}

/* end */

.sidebar_nav .ant-menu-light .ant-menu-item-selected {
    background-color: #4A51C7;
    color: #fff;
    text-decoration: none;
}

.sidebar_nav .ant-menu .ant-menu-item {
    border-radius: 0;
    width: 100%;
    margin: 0;
    /* padding: 12px 12px 12px 0px; */
    margin-bottom: 4px;
    padding-left: 14px !important;
    padding-top: 4px;
}

.sidebar_nav .ant-menu-inline .ant-menu-submenu-title {
    margin-inline: 0;
    padding-inline: 0;
    padding-left: 14px !important;
}
.sidebar_nav .ant-menu-sub .ant-menu-title-content{
    padding-left: 24px;
}
.sidebar_nav .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
    padding-left: 10px !important;
}



.sidebar_nav .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
    background-color: #4A51C7;
    color: #fff;
}

/* submenu css  */
.sidebar_nav .sidebar_nav .ant-menu .ant-menu-submenu {
    padding-left: 0;
    border-radius: 0;
    width: 100%;
    margin: 0;
}

.sidebar_nav .ant-menu-submenu {
    border-radius: 0;
    border-radius: 0;
    width: 100%;
    margin: 0;
    margin-bottom: 4px;
    /* padding-left: 22px; */
    padding-top: 4px;
}

.sidebar_nav .ant-menu-submenu-title {
    border-radius: 0;
    width: 100%;
}

.sidebar_nav .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
    background-color: #4A51C7;
}

.sidebar_nav .ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title {
    color: #fff;
}

/* this css has not wrapper class override direct from antd start */
.ant-menu-light.ant-menu-submenu-popup>.ant-menu,
.ant-menu-light.ant-menu-submenu-popup>.ant-menu:hover,
.ant-menu-light.ant-menu-submenu-popup>.ant-menu:active,
:where(.css-dev-only-do-not-override-1qhpsh8).ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover,
:where(.css-dev-only-do-not-override-1qhpsh8).ant-menu-light>.ant-menu .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover {
    background-color: #2b318a;
    color: #fff;
}

.ant-menu-light>.ant-menu .ant-menu-item-selected,
.ant-menu-light>.ant-menu .ant-menu-item-selected:hover,
.ant-menu-light>.ant-menu .ant-menu-item-selected:active {
    background-color: #4A51C7;
    color: #fff;
}

.ant-tooltip .ant-tooltip-inner {
    background-color: #2b318a;
    text-decoration: none;
}

.ant-tooltip .ant-tooltip-arrow {
    --antd-arrow-background-color: #2b318a;
}

/* this css has not wrapper class override direct from antd end */
.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline::before {
    content: "";
    border-right: 1px solid #fff;
}

/* end submenu css */

.ant-layout .ant-layout-sider-collapsed{
    flex: 0 0 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;
    width: 60px !important;
}