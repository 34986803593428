.summary_chart_wrapper .ant-table-wrapper .ant-table-thead>tr>th {
    padding: 10px 12px;
    font-size: 13px;
    background: #F3F3FE;
}

.summary_chart_wrapper .ant-table-wrapper .ant-table-tbody>tr>td {
    padding: 15px 20px;
    font-size: 13px;
}

.summary_chart_wrapper .table_btn_severity {
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--X-Biz-Styles-Status-High, #F50000);
    color: #F50000;

}

.summary_chart_wrapper .threat_summary_title {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
}
.summary_chart_table .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
    border-start-start-radius: 0px;
}
.summary_chart_table .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
    border-start-end-radius: 8px;
}