.basic-details .heading{
    color: #232337;
    font-size: 14px;
    font-weight: 600;
    font-family: Open Sans;
    
}
.basic-details .overview{
    color: #63637D;
    font-size: 13px;
    font-weight: 400;
    font-family: Open Sans;

}
.second-component{
    display: flex;
}
.second-component .title{
    font-size: 13px;
    font-weight: 600;
    color: #232337;
    margin-top: 10px;
}
.second-component .sub-title{
    font-size: 12px;
    font-weight: 600;
    color: #63637D;
}
.card-value{
    font-weight: 600;
    font-size: 13px;
}
.chart-overlay{
    display: flex;
    position: relative;
    top: 50px;
    justify-content: center;
    z-index: 1;
}
.chart-overlay-violation{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    top: 40px;
    left: 105px;
    z-index: 1;
    /* align-items: center; */
    width: 165px;
    height: 165px;
    gap: 0px;
    opacity: 0px;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px 0px #00000014;
}
.violation-ellipse_circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 148px;
    height: 148px;
    gap: 0px;
    opacity: 0px;
    border: 2px solid #0EBC8B;
    border-radius: 50%;
    box-shadow: 0px 4px 24px 0px #00000014;
    background: #02B58333;

}
.violation-secteam_confirm {
    font-family: 'Open Sans';
    font-size: 40px;
    font-weight: 600;
    line-height: 21.12px;
    text-align: center;
    color: #02B583;
    padding-bottom: 15px;
}
