.additional_rules_form {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 400px;
    overflow-y: scroll;
}
.additional_rules_form::-webkit-scrollbar{
    width: 3px;
}
.additional_rules_form .heading_form_1 {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    color: #232337;
}

/* .ehsan .ant-collapse-borderless >.ant-collapse-item>.ant-collapse-content{
    background: #f8d6d6;
}
.ehsan .ant-collapse-header{
    background: #F6F6FF;
    border-radius: 0;
}
.ehsan{ border: 1px solid #cccc;} */

.additional_rules_form .custom_label_class {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 21.79px;
    text-align: left;
    color: #63637D;
}

.additional_rules_form .cancel_btn {
    padding: 6px 14px 6px 14px;
    gap: 4px;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    border: 1px solid #5A81FA;
    box-shadow: 0px 2px 12px 0px #3F4BFF66;
    background: #fff;
}

.additional_rules_form .save_btn {
    padding: 6px 14px 6px 14px;
    gap: 4px;
    border-radius: 4px;
    opacity: 0px;
    box-shadow: 0px 2px 12px 0px #D9D9D9;
    background: #D9D9D9;
    border: 1px solid #D9D9D9;
    color: #fff;
}

.additional_rules_form .add_rule_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    opacity: 0px;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    text-align: left;
    color: #5A81FA;
    border: none;
    background: transparent;
}