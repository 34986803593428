.selectable_table_row .ant-table-wrapper .ant-table-thead>tr>th {
    background: #F3F3FE;
}

.selectable_table_row .ant-table-wrapper .ant-table-thead>tr>th::before {
    display: none;
}

.selectable_table_row .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
    border-start-start-radius: 0;
}

.selectable_table_row .ant-table-wrapper .ant-table-container table>thead>tr:last-child>*:last-child {
    border-start-end-radius: 0;
}