.table_view .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
    border-start-start-radius: 0px
}

.table_view .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
    border-start-end-radius: 0px
}

.table_view .ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
}

.table_view .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>td {
    font-size: 13px;
    font-family: Open Sans;
    font-weight: 400;
    line-height: 19.07px;
    text-align: left;
    color: #232337;
}

.table_view .ant-table-wrapper .ant-table.ant-table-small .ant-table-thead>tr>th {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 600;
    line-height: 19.07px;
    text-align: left;
    color: #63637D;
}