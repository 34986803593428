.dashboard_options {
    /* background: #ECECF6; */
    /* height: 100vh; */
    max-width: 1920px;
    margin: auto;
}

.dashboard_options header {
    background: #fff;
    display: flex;
    justify-content: space-between;
    height: 68px;
    padding: 14px 24px;
    width: 100%;
    margin-bottom: 24px;
}

.dashboard_options .main_content_body {
    padding: 24px;
    height: 100vh;
}

.dashboard_options .logo {
    width: 140px;
    height: 40px;
}

.dashboard_options .logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dashboard_options .log_out_btn {
    Padding: 6px 14px;
    color: #5A81FA;
    border: 1px solid #5A81FA;
    border-radius: 4px;
    background: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.07px;

}

.main_content_body .top_header_content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 20px;
}

.top_header_content .header_title {
    font-size: 28px;
    font-weight: 600;
    line-height: 19px;
    color: #232337;
}

.top_header_content .header_sub_title {
    font-size: 18px;
    font-weight: 400;
    line-height: 19px;
    color: #232337;
}

.top_header_content .header_description {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #9494AE;
}

.dashboard_options .main_content_body .card_option {
    max-width: 453px;
    max-height: 223px;
    min-height: 200px;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0px 0px 40px 0px #34387814;
}

.dashboard_options .card_option .icon_parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    filter: grayscale(1);
    color: #5A81FA;
}

.dashboard_options .card_option .icon_parent .title_name {
    color: #232337;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
}

/* .dashboard_options .card_option .icon_parent {
    filter: grayscale(1);
    color: #5A81FA;
} */

.dashboard_options .card_option:hover .icon_parent,
.dashboard_options:hover .icon_parent .title_name {
    filter: brightness(1);
    color: #5A81FA;
}

.dashboard_options .continue_btn {
    width: 119px;
    height: 46px;
    padding: 12px 24px;
    gap: 8px;
    border-radius: 8px;
    background: #5A81FA;
    box-shadow: 0px 2px 12px 0px #3F4BFF66;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    text-transform: capitalize;
}

.defoult_dashboard {
    border: 1px solid #5A81FA;
}

.defoult_dashboard::after {
    content: "defoult";
    width: 70px;
    background: #5A81FA;
    color: #fff;
    border-radius: 8px;
    font-size: 14px;
    position: absolute;
    top: -10px;
    right: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    text-transform: capitalize;
    font-weight: 400;
    font-style: normal;
    line-height: 19px;
}