.main{
    padding-bottom: 15px;
}
.maintenance .maintenance-title{
    font-weight: 600;
    font-size: 16px;
    color: #232337;
}
.maintenance .maintenance-sub-title{
    font-weight: 600;
    font-size: 14px;
    color: #02B583;
    text-transform: uppercase;
}
.maintenance .maintenance-title{
    font-weight: 600;
    font-size: 16px;
    color: #232337;
}
.popularity .popularity-title{
    font-weight: 600;
    font-size: 14px;
    color: #232337;
    text-transform: capitalize;
}
.popularity .popularity-sub-title{
    font-weight: 600;
    font-size: 14px;
    color: #5A81FA;
    text-transform: uppercase;
}
.popularity .secondary{
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
}
.maintenance .secondary{
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
}
.popularity .secondary-title{
    font-weight: 600;
    font-size: 16px;
    color: #5A81FA;
}
.maintenance .secondary-title{
    font-weight: 600;
    font-size: 16px;
    color: #5A81FA;
}
.popularity .secondary-sub-title{
    height: 32px;
    width: 145px; 
    font-weight: 600;
    font-size: 16px;
    color: #5A81FA;
}

.secondary-sub-title span.ant-select-selection-item {
    font-size: 14px;
    font-weight: 600;
    color: #63637D;
}