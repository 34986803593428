.dashboard .ant-card .ant-card-body {
    padding: 0;
}

.dashboard {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/* .recharts-wrapper svg {
    width: 100%;
    height: 100%;
} */

.dashboard .dashboard_head_des_parent {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
    margin-right: 10px;
}



.dashboard .insight_report {
    /* padding: 4px 12px; */
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #5a81fa;
    text-align: left;
    gap: 4px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.dashboard .commo_chat_header {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-family: "Open Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    display: flex;
    gap: 6px;
    justify-content: flex-start;
    align-items: center;
    /* padding: 8px; */
}

.dashboard .scan_history {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
}

.area_chat_wrapper .area_chat_content {
    padding: 0 12px;
}

.dashboard .custom_legend_overall_risk_gauge{
    max-width: 250px;
    margin: auto;
    width: 175px;
}

.dashboard .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
}
 .custom_legend_overall_risk_gauge .dot_text {
    font-family: Open Sans;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #63637D;    
}

.dashboard .count_content {
    color: var(--X-Biz-Styles-Typography-Sub-heading, #63637D);
    font-family: "Open Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
}

.dashboard .percentage {
    color: var(--X-Biz-Styles-Typography-Sub-heading, #63637D);
    text-align: right;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.dashboard .month_label {
    color: var(--X-Biz-Styles-Typography-Sub-heading, #63637D);
    text-align: right;
    font-family: "Open Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dashboard .area_chat_wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.dashboard .instance_count {
    color: var(--X-Biz-Styles-Typography-Sub-heading, #63637D);
    text-align: right;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.dashboard .instance_label {
    color: var(--X-Biz-Styles-Typography-Sub-heading, #63637D);
    text-align: right;
    font-family: "Open Sans";
    font-size: 8px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.dashboard .discovered_assets {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.dashboard .discovered_assets .list {
    display: flex;
    /* padding: 12px; */
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    align-self: stretch;
    border-radius: 8px;
    height: 350px;
    overflow: auto;
}

.discovered_assets .list::-webkit-scrollbar {
    display: none;
}

.list .list_card_item .head {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px
}

.list .list_card_item .item {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-family: "Open Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
}

.list .list_card_item .item_label {
    color: var(--X-Biz-Styles-Typography-Sub-heading, #63637D);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
}

.dashboard .list .list_card_item {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border: 1px solid var(--X-Biz-Styles-Cards-Internal-card-stroke, #EBEEF3);
    background: #FFF;
}

.list .item_body {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.dashboard .assets_header .assets_title {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
}

.discovered_assets .discovered_count {
    padding: 2px 6px;
    border-radius: 4px;
    background: var(--X-Biz-Styles-Other-Primary-light, #5A81FA);
    color: #FFF;
}

.dashboard .view_more_or_delete {
    display: flex;
    padding-bottom: 1px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-bottom: 1px solid var(--X-Biz-Styles-Other-Primary-light, #5A81FA);
    text-decoration: none;
}

 .dashboard .discovered_assets .view_more_or_delete .view {
    color: var(--X-Biz-Styles-Other-Primary-light, #5A81FA);
    font-family: "Open Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.dashboard .over_all_risk_description {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #9494a8;
}



.dashboard .company_select .ant-select-selector {
    background: transparent;
    /* border-color: transparent; */
}

.dashboard .company_select .ant-select-selector:hover {
    border-color: transparent;
}

.fsm_text_ellipse {
    /* height: 20px; */
    margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}



/* dropdown more css */
.view_trend_summary {
    display: flex;
    gap: 6px;
    font-size: 14px;
    line-height: 19.07px;
    text-decoration: none;
    color: #5A81FA !important;
}

.view_details {
    display: flex;
    gap: 6px;
    font-size: 14px;
    line-height: 19.07px;
    text-decoration: none;
    color: #5A81FA !important;
}

/* end */
.modal_content_wrap .report_type_label {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #63637D;
    margin-bottom: 12px;
}

/* make card border-radius from rechart wrapper start */
.svg_wrapper svg {
    border-radius: 0 0 8px 8px;
}

/* make card border-radius from rechart wrapper end */




@media (min-width: 1440px) {

    .dashboard .count_content {
        font-size: 30px;
    }

    .dashboard .instance_label {
        font-size: 9px;
    }

    .dashboard .instance_count {
        font-size: 22px;
    }


}

.scan_list_dropdown {
    width: 180px;
    height: 150px;
    overflow: auto;
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    /* padding: 10px 12px; */
}

.scan_list_dropdown {
    max-height: 200px;
    overflow-y: auto;
}

.scan_list_dropdown::-webkit-scrollbar {
    width: 2px;
    background: #e0e3ee;
}

.scan_list_dropdown::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: #888;
    height: 40%;
    /* Set the height of the thumb bar */
    width: 2px;
}

.scan_list_dropdown .history_date {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #232337;
}

.scan_list_dropdown .history_time {
    font-family: Open Sans;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;

}

.scan_list_dropdown .parent_div_menu_item {
    padding: 12px 16px;
    gap: 10px;
    border-bottom: 1px solid #727B9A33;

}

.dashboard .assets {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #232337;
}

.dashboard .view_summary {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 16px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    border-top: 1px solid #727B9A33;
    font-family: Open Sans;
    font-size: 11px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #232337;
    cursor: pointer;
    text-transform: uppercase;
}

.ehsan_responsive_container {
    width: 160px !important;
    height: 130px !important;
}

.responsive_overall_risk_container {
    width: 175px !important;
    height: 125px !important;
}
 
 .active_btn{
    border: 1px solid #5A81FA;
}


