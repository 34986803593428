/* .tabs_with_btn .ant-tabs-nav::before {
    border-bottom: 0;
}

.tabs_with_btn .extra_btn {
    display: flex;
    gap: 2px;
} */
.ant-tabs .ant-tabs-nav .ant-tabs-tab {
    color: var(--tabs-text-color); 
    font-size: 13px;
  }