.view_summary .count_error_title {
    font-size: 16px;
    font-weight: 700;
    line-height: 21.79px;
    color: #FFFFFF;
    background: #F14336;
    border-radius: 4px;
    padding: 8px 12px 8px 12px;
    font-style: normal;
    margin-bottom: 32px;
}

.view_summary .chart_summary {
    background: #FFFFFF;
    /* border: 1px solid #727B9A33; */
    padding: 16px 26px 16px 16px;
    /* border-radius: 12px; */
    margin-bottom: 16px;
}

.view_summary .chart_summary .chart_title {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: #232337;
    font-style: normal;
}

.view_summary .threat_list_head_wrap,
.view_summary .certificate_threat_list {
    margin-bottom: 16px;
}

.view_summary .certificate_threat_list .label_threat_list {
    font-size: 14px;
    line-height: 19.07px;
    font-weight: 600;
    color: #232337;
    margin-bottom: 0;
}

.view_summary .certificate_threat_list .label_desc_threat_list {
    font-size: 14px;
    line-height: 19.07px;
    font-weight: 400;
    color: #63637D;
    margin-bottom: 0;
}

.view_summary .certificate_threat_list .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
    border-start-start-radius: 0px;
}

.view_summary .certificate_threat_list .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
    border-start-end-radius: 0px;
}

.view_summary .certificate_threat_list .ant-table-wrapper .ant-table-container table>thead>tr>th {
    background: #F3F3FE;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    text-align: left;
    color: #63637D;
}

.view_summary .certificate_threat_list .ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td {
    padding: 10px 8px;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.07px;
    text-align: left;
    color: #232337;
}

.view_summary .cta_btn_view_more .view_more_btn {
    padding: 12px 24px;
    border: 1px solid #5A81FA;
    box-shadow: 0px 2px 12px 0px #3F4BFF66;
    color: #5A81FA;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    width: 100%;
}

.view_summary .chart_pointer_close {
    width: 20px;
    height: 20px;
}