.top-issues .ant-select {
    width: 130px;
    height: 32px;
    border: 1px solid rgb(210, 216, 233);
    border-radius: 7px;
    font-size: 14px;
}
.top-issues .ant-select-selection-item{
    font-size: 12px;
    font-weight: 600;
    color: #63637D;
}
.top-issues{
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 600;
}
.view-more{
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #5A81FA;
    border-radius: 8px;
    height: 40px;
    padding: 12px 24px 12px 24px;
    margin-top: 20px;
    color: #5A81FA;
    font-size: 13px;
    font-weight: 600;
}
.list .list_card_item .topissue_title strong{
    color: #232337;
    font-weight: 600;
    font-size: 14px;
    line-height: "112%";
}
.list .list_card_item .topissue_title span{
    color: #232337;
    font-weight: 400;
    font-size: 12px;
    line-height: "112%";
}
.list .list_card_item .topissue_para{
    color: #63637D;
    font-size: 12px;
    line-height: "112%";
}

@media (max-width: 1366px) {
    .list .list_card_item .topissue_title strong{
        color: #232337;
        font-weight: 600;
        font-size: 13px;
    }
    .list .list_card_item .topissue_title span{
        color: #232337;
        font-weight: 400;
        font-size: 11px;
    }
    .list .list_card_item .topissue_para{
        color: #63637D;
        font-size: 11px;
    }
}