.container {
  width: 100%;
}

.top-threats-parent {
  display: flex;
}

.top-threats {
  padding-bottom: 12px;
}

.asset-distribution-map svg {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 330px;
}

.asset-distribution-map path {
  fill: #cacaf3;
}

.flagSelect {
  /* min-width: 200px; */
}

.country_selector {
  max-height: 320px;
  overflow: auto;
}

.country_selector .ReactFlagsSelect-module_selectOptions__3LNBJ {
  border: none;
}

.country_selector::-webkit-scrollbar,
.country_selector .ReactFlagsSelect-module_selectOptions__3LNBJ::-webkit-scrollbar {
  display: none;
}

.flagSelect .ReactFlagsSelect-module_flagsSelect__2pfa2 {
  padding-bottom: 0px;
}

.dropdown_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.custom-flag-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.flag-icon {
  width: 20px;
  /* Adjust the width as needed */
  height: auto;
  margin-right: 8px;
  /* Adjust the margin as needed */
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border-bottom: 1px solid rgba(114, 123, 154, 0.2);
}

.country_selector button {
  border: none;
  border-bottom: 1px solid var(--X-Biz-Styles-Other-Stroke, rgba(114, 123, 154, 0.20));
  border-radius: 0;
}



.asset-distribution-map {
  flex-grow: 1;
  /* Make the map take up remaining space */
  margin-left: 16px;
  /* Adjust the margin as needed */
}

/* .top-threats-parent .assets_wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
} */

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .top-threats-parent {
    flex-direction: column;
    height: auto;
  }

  .asset-distribution-map {
    margin-left: 0;
    /* Remove margin for smaller screens */
    margin-top: 16px;
    /* Add margin on top for smaller screens */
  }
}

.asset-distribution {
  position: relative;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  font-family: 'Open Sans';
  color: #232337;
  text-align: left;
}

.panel_list_item {
  font-family: 'Open Sans';
  font-size: 9px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #63637D;
  border-bottom: 0.5px solid #727B9A33;
  padding: 6px 0px;
  overflow: hidden;
}

.country_selector .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  font-size: 12px;
  padding: 6px 12px;
}

.country_selector .country {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
  color: #63637D;
}