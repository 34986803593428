.card_view .value {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #232337;
}

.card_view .text_label {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #63637D;
}