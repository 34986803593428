.third_party_mngmnt_dashbord .ant-card .ant-card-body {
    padding: 0;
}

.third_party_mngmnt_dashbord {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.third_party_mngmnt_dashbord .dashboard_head_des_parent {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
}

.third_party_mngmnt_dashbord .welcome-to-tata-container {
    font-size: 20px;
    line-height: 19px;
    white-space: pre-wrap;
    text-align: left;
    /* display: inline-block; */
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.third_party_mngmnt_dashbord .welcome-to {
    font-weight: 600;
}

.third_party_mngmnt_dashbord .tata-group {
    color: #5a81fa;
}

.third_party_mngmnt_dashbord .title_with_wrap_des {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.third_party_mngmnt_dashbord .title_with_wrap_des .stay_ahead_of {
    position: relative;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Open Sans';
    color: #63637d;
    text-align: left;
}

.third_party_mngmnt_dashbord .showing_sync_ver {
    display: inline-flex;
    gap: 16px;
    align-items: center;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #232337;
    text-align: left;
}

.third_party_mngmnt_dashbord .th_jan_2024 {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #232337;
    text-align: left;
}

.third_party_mngmnt_dashbord .showing_sync_ver_as_on_parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.third_party_mngmnt_dashbord .insight_report {
    /* padding: 4px 12px; */
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #5a81fa;
    text-align: left;
    gap: 4px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.third_party_mngmnt_dashbord .commo_chat_header {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;

}

.third_party_mngmnt_dashbord .scan_history {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
}

.third_party_mngmnt_dashbord .company_select .ant-select-selector {
    background: transparent;
    /* border-color: transparent; */
}

.third_party_mngmnt_dashbord .risk_assessment_card .start_assessment_btn {
    background: "#5A81FA14";
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px dashed #5A81FA;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #5A81FA;
}

.third_party_mngmnt_dashbord .vendor_details_info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.vendor_details_info .vendor_comp {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 21.79px;
    text-align: left;
    color: #232337;
}

.vendor_details_info .vendor_schedule_completion_label {
    font-family: Open Sans;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 16.34px;
    text-align: left;
    color: #63637D;
}

.vendor_details_info .vendor_schedule_completion_date {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    text-align: left;
    color: #63637D;
}

.summary_drawer .cta_btn_view_more .view_more_btn {
    padding: 12px 24px;
    border: 1px solid #5A81FA;
    box-shadow: 0px 2px 12px 0px #3F4BFF66;
    color: #5A81FA;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    width: 100%;
}

.drawer_wrapper_container .header_drawer .drawer_title {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    text-align: left;
    color: #232337;
}

.drawer_wrapper_container .card_drawer {
    padding: 72px 16px 8px 16px;
    gap: 0px;
    border-radius: 6px;
    opacity: 0px;
    box-shadow: 0px 0px 40px 0px #34387814;
    background: #FFFFFF;

}

.card_drawer .item_label {
    padding: 10px 16px 10px 10px;
    gap: 8px;
    border: 0px 0px 1px 0px;
    opacity: 0px;
    background: #FFFFFF;
    border-bottom: 1px solid #727B9A33;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #63637D;
}

.card_drawer .item_value {
    padding: 10px 16px 10px 10px;
    gap: 8px;
    border: 0px 0px 1px 0px;
    opacity: 0px;
    background: #FFFFFF;
    border-bottom: 1px solid #727B9A33;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #232337;
}

.drawer_wrapper_container .ellipse_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 148px;
    height: 148px;
    gap: 0px;
    opacity: 0px;
    border: 2px solid #0EBC8B;
    border-radius: 50%;
    box-shadow: 0px 4px 24px 0px #00000014;
    background: #02B58333;

}

.drawer_wrapper_container .group_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 166px;
    height: 166px;
    gap: 0px;
    opacity: 0px;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px 0px #00000014;
    margin-bottom: -77px;
    z-index: 1;
}

.drawer_wrapper_container .secteam_confirm {
    width: 101px;
    height: 42px;
    gap: 0px;
    opacity: 0px;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.12px;
    text-align: center;
    color: #02B583;
    ;
}

.drawer_collapse .ant-collapse-content>.ant-collapse-content-box {
    background-color: #fff;
}

.third_party_mngmnt_dashbord .vendor_list_card_wrapper .view_more {
    padding: 0px 0px 1px 0px;
    border-bottom: 1px solid #5A81FA;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.07px;
    text-align: left;
    color: #5A81FA;
    text-decoration: none;
}

.third_party_mngmnt_dashbord .vendor_list_card_wrapper .filter_and_view_btn {
    display: flex;
    align-items: center;
    gap: 16px;
}

.third_party_mngmnt_dashbord .vendor_list_card_wrapper .vendor_list_title {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #232337;
}
.third_party_mngmnt_dashbord .vendor_list_card_wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
}