.notation_header_count {
    display: flex;
    gap: 16px;
}
.notation_header_count.customStyle {
    display: flex;
    justify-content: space-around;
    gap: 0px;
}
.notation_header_count.popularityStyle {
    display: flex;
    /* justify-content: center; */
    gap: 0px;
}

.notation_header_count .item {
    height: 50px;
    padding: 0px 14px 0px 0px;
    gap: 16px;
    border-left: 3px solid #5A81FA;
}
.notation_header_count .item.customStyle {
    padding: 0px 1px 0px 0px;
    gap: 16px;
    border-left: 3px solid #5A81FA;
    height: 35px;
}
.notation_header_count .item.popularityStyle {
    padding: 0px 1px 0px 0px;
    gap: 16px;
    border-left: 3px solid #5A81FA;
}

.notation_header_count .item_frame {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 0 12px;
}
.notation_header_count .item_frame.customStyle {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0 16px;
}
.notation_header_count .item_frame.popularityStyle {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0 12px;
}
.scan-detail-side-drawer .ant-drawer-body{
    padding: 0px 0px 24px;
  }
.notation_header_count .item_frame .count_number {
    font-family: Open Sans;
    font-size: 35px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    color: #5A81FA;
}
.notation_header_count .item_frame .count_number.customStyle {
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #5A81FA;
}
.notation_header_count .item_frame .count_number.popularityStyle {
    font-family: Open Sans;
    font-size: 17px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
    color: #5A81FA;
}

.notation_header_count .item_frame .count_label {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #9494AE;
}
.notation_header_count .item_frame .count_label.customStyle {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #9494AE;
}
.notation_header_count .item_frame .count_label.popularityStyle {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
    color: #9494AE;
}
