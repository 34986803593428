.security-tabs .title{
    color: #232337;
    font-weight: 600;
    font-size: 16px;
}
.security-tabs .sub-title{
    color: #02B583;
    font-weight: 700;
    font-size: 14px;
}
.security-tabs .second-title{
    padding-top: 10px;
}
.security-tabs .sub-head{
    font-size: 12px;
    font-weight: 600;
    color: #63637D;
}
.security-tabs .paragraph{
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #5A81FA;
}
