.common_table .ant-table-wrapper .ant-table-thead>tr>th {
    background: #F3F3FE;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #63637D;
}

.common_table .ant-table-wrapper .ant-table-tbody>tr>td {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.column_dropdown {
    width: 300px;
    max-height: 500px;
    overflow-y: auto;
}

.column_dropdown::-webkit-scrollbar {
    width: 3px;
}

.column_dropdown .menu_item {
    border-bottom: 1px solid #727B9A33;
    border-radius: 0;
}
.table_row{
    margin-bottom: 14px;
}
.table_row .add_andriod_btn {
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    border: 1px solid #5A81FA;
    padding: 4px 10px 4px 10px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 12px;
    color: #fff;
    background-color: #5A81FA;
    box-shadow: 0px 2px 12px 0px #3F4BFF66;
}

.table_row .filter_label_btn {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 4px 10px;
    border-radius: 4px 0px 0px 0px;
    opacity: 0px;
    background: #5A81FA;
    box-shadow: 0px 2px 12px 0px #3F4BFF66;
    border: 1px solid #5A81FA;
    border-radius: 8px;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    text-align: left;
    color: #FFFFFF;
    text-decoration: none;
    width: 74px;
    height: 28px;
}

.table_row .download_excel_btn,
.table_row .download_excel_btn:hover {
    padding: 4px 10px;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #5A81FA;
    opacity: 0px;
    box-shadow: 0px 2px 12px 0px #3F4BFF66;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    text-align: left;
    color: #5A81FA;
}

.table_row .card_view_btn {
    display: flex;
    align-items: center;
    gap: 4px;
    opacity: 0px;
    text-decoration: none;
   background: #FFFFFF;
   border: none;
   color: #63637D;
}
.table_row .card_view_btn .card_view_text{
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #63637D;
    border: none;
    text-transform: capitalize;
    width: 100%;
}
.table_row .card_view_btn img{
    width: 18px;
    height: 18px;
    object-fit: cover;
}

.table_row .filter_switch {
    display: flex;
    align-items: center;
    gap: 6px;
    opacity: 0px;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
    color: #63637D;
    text-transform: capitalize;
}
.common_table  .custom_switch,
.table_row .custom_switch{
    height: 14px;
}
 .common_table .ant-switch.ant-switch-small .ant-switch-handle,
 .table_row .ant-switch.ant-switch-small .ant-switch-handle{
    height: 10px;
}
 .common_table .column-icon {
    text-decoration: "none";
    border-radius: 6;
    border: "1px solid #D2D8E9";
    padding: "4px 10px";
    background: "#FFFFFF";
    font-size: 12;
    font-weight: 600;
    color: "#63637D";
    display: "flex";
    gap: "6px";
    align-items: "center";
}