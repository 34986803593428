*{
    font-family: Open Sans;
}
.collapse-discovered-license .detail{
    color: #232337;
    font-size: 14px;
    font-weight: 600;
}
.collapse-discovered-license .sub-detail{
    color: #63637D;
    font-size: 11px;
    font-weight: 600;
}
.collapse-discovered-license .sub-detail-para{
    color: #63637D;
    font-size: 12px;
    font-weight: 600;
    margin-top: 12px;
}
.discovered-icons .flex-style{
    justify-content: space-around;
}
.discovered-icons .discovered-exclamation-icon-style{
    margin-right: 5px;
    padding: 5px;
    border-radius: 20px;
    height: 31px;
    border: 1px solid transparent;
    width: 32px;
}
.discovered-icons .discovered-icon-detail{
   margin-top: 15px;
   font-size: 13px;
   color: #63637D;
   font-weight: 600;
   white-space: nowrap;
}
.discovered-icons .discovered-title {
    font-size: 14px;
    font-weight: 600;
    color: #232337;
}
.collapse-discovered-license div#rc-tabs-5-tab-all_details{
    font-size: 13px;
    font-weight: 600;
}
.collapse-discovered-license span.ant-collapse-header-text{
    font-size: 12px;
    font-weight: 600;
    color: #232337;
}