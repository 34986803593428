*{
    font-family: Open Sans;
}
.collapse-dependency .detail{
    color: #232337;
    font-size: 14px;
    font-weight: 600;
}
.collapse-dependency .sub-detail{
    color: #63637D;
    font-size: 11px;
    font-weight: 600;
}
.icons .exclamation-icon-style{
    margin-right: 5px;
    padding: 5px;
    border-radius: 20px;
    height: 31px;
    border: 1px solid transparent;
    width: 32px;
}
.icons .icon-detail{
   margin-right: 30px;
   margin-top: 15px;
   font-size: 13px;
   color: #63637D;
   font-weight: 600;
}
.collapse-dependency div#rc-tabs-5-tab-all_details{
    font-size: 13px;
    font-weight: 600;
}
.collapse-dependency span.ant-collapse-header-text{
    font-size: 12px;
    font-weight: 600;
    color: #232337;
}