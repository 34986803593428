@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  background-color: #ECECF6;
  font-family: 'Open Sans !important';
  cursor: default;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.toggle_btn_div {
  position: absolute;
  top: 20px;
  right: -7px;
  cursor: pointer;
  z-index: 100;
  /* background: #2b318a; */
  /* color: #fff; */
  /* border-radius: 50%; */
  /* border: 2px solid #fff; */
  width: 20px;
  height: 20px;
  /* display: flex;
  justify-content: center; */
}

.sidebar_menu {
  background: #2b318a;
  color: white;
}

.main_content {
  padding: 36px 30px 60px 30px;
}


/* common table header bg and font with td font css */
.ant-table-wrapper .ant-table-thead>tr>th {
  background: #F3F3FE;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #63637D;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

/* common table header bg and font with td font css end */
.ant-menu-title-content,
.ant-menu-submenu-arrow,
.ant-menu-light .ant-menu-submenu-title {
  color: white;
}

/* pagination global style */

.ant-pagination .ant-pagination-item{
  border-radius: 2px;
  background-color: #ECECF6;
  min-width: 30px;
  height: 30px;

}
.ant-pagination .ant-pagination-item-active{
  background-color: #5A81FA;
  color: #FFFFFF;
}