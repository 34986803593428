.vulnerability_custom_table th.ant-table-cell.ant-table-cell-ellipsis.ant-table-column-has-sorters{
    font-size: 13px !important;
    font-weight: 600;
    padding: 10px !important;
}
.vulnerability_custom_table td.ant-table-cell.ant-table-cell-ellipsis {
    padding: 10px !important;
}
.vulnerability_custom_table .affected-assets {
  color: #5A81FA;
  font-weight: 600;
}
.vulnerability_custom_table .license {
  color: #E10505;
  font-weight: 600;
}
.vulnerability_custom_table .license-true {
  color: #02B583;
  font-weight: 600;
}
.license-side-drawer .small-head{
  color: #63637D;
  font-weight: 400;
  font-size: 12px;
  font-family: Open Sans;
}
.license-side-drawer .main-head{
  color: #232337;
  font-weight: 600;
  font-size: 14px;
  font-family: Open Sans;
  padding-bottom: 10px;
}
.license-side-drawer .paragraph{
  color: #232337;
  font-weight: 400;
  font-size: 13px;
  font-family: Open Sans;
}
.license-side-drawer .second-div{
  display: flex;
  justify-content: space-between;
  color: #232337;
  font-weight: 600;
  font-size: 14px;
  font-family: 'Open Sans';
  padding-bottom: 10px;
}
.license-side-drawer .main-part{
  display: flex;
  justify-content: space-between;
  color: #63637D;
  font-weight: 600;
  font-size: 14px;
  font-family: Open Sans;
}
.license-side-drawer .main-part .details{
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
}
.license-side-drawer .main-part .details .icon-style{
  font-size: 25px;
  margin-right: 5px;
  border-radius: 10px;
}
.license-side-drawer .main-part .details .exclamamtion-icon-style{
  margin-right: 5px;
  padding: 5px;
    border-radius: 20px;
    height: 25px;
    border: 1px solid transparent;
    width: 25px;
}
.component-side-drawer .ant-drawer-title{
  font-size: 20px;
  font-weight: 600;
  color: #232337;
}
.component-side-drawer .chart-design{
  font-size: 18px;
  font-weight: 600;
  color: #232337;
}
.component-side-drawer .side-content{
  display: grid;
}
.component-side-drawer .title{
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #63637D;
}
.component-side-drawer .sub-title{
  border: 1px solid #02B583;
  padding: 6px 10px 6px 10px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 700;
  width: fit-content;
  text-transform: uppercase;
}
.common_table .column-icon{
  text-decoration: none;
    border-radius: 6px;
    border: 1px solid rgb(210, 216, 233);
    padding: 4px 10px;
    background: rgb(255, 255, 255);
    font-size: 12px;
    font-weight: 600;
    color: rgb(99, 99, 125);
    display: flex;
    gap: 6px;
    align-items: center;
}