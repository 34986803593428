.global_filter .ant-card-body {
    padding: 0;
}

.global_filter .header_parent_wrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;

}

.global_filter .card_wrapper {
    border-bottom: 1px solid #727B9A33;
}

.global_filter .reset_all {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #5A81FA;
    border: none;
    background: #fff;
}

.global_filter .filter_title,
.global_filter .score_section .score_title,
.global_filter .currency_section .currency_title,
.global_filter .category_section .category_title,
.global_filter .price_section .price_title {
    color: #232337;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
}

.global_filter .score_section .ant-collapse>.ant-collapse-item>.ant-collapse-header,
.global_filter .price_section .ant-collapse>.ant-collapse-item>.ant-collapse-header,
.global_filter .category_section .ant-collapse>.ant-collapse-item>.ant-collapse-header,
.global_filter .currency_section .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    flex-direction: row-reverse;
}

.collapse_reverse_header {
    flex-direction: row-reverse;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.88);
    line-height: 1.5714285714285714;
    cursor: pointer;
    transition: all 0.3s, visibility 0s;
}

.global_filter .currency_list {
    height: 154px;
    overflow-y: auto;
}

.global_filter .currency_list::-webkit-scrollbar {
    width: 2px;
    background: #ececec;
}