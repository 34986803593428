.table-container {
    max-height: 400px; 
    overflow-y: auto;
  }
  
  .ant-table-wrapper {
    height: auto !important; 
  }
  .ant-pagination-prev, .ant-pagination-next, .ant-pagination-prev a, .ant-pagination-next a {
    color: #9494AE !important;
  }
  
  .policy_summary_chart .ant-table-wrapper .ant-table-thead>tr>th {
    padding: 10px 12px;
    font-size: 13px;
    background: #F3F3FE;
}

.policy_summary_chart .ant-table-wrapper .ant-table-tbody>tr>td {
    padding: 15px 20px;
    font-size: 13px;
    font-weight: 400;
    color: #232337;
}

.policy_summary_chart .table_btn_severity {
    display: flex;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    border: 1px solid var(--X-Biz-Styles-Status-High, #F50000);
    color: #F50000;

}

.policy_summary_chart .threat_summary_title {
    color: var(--X-Biz-Styles-Typography-Heading, #232337);
    font-family: "Open Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
}