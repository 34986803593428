.notification_modal .ant-modal-content {
    padding: 12px;
    /* height: 100vh;
    overflow: auto */
    max-height: 100vh;
    /* overflow: -moz-hidden-unscrollable; */
}

.notification_modal .notification_item_wrapper_container {
    max-height: 70vh;
    overflow: auto;
}

.notification_modal .notification_item_wrapper_container::-webkit-scrollbar {
    display: none;
}

.notification_modal .ant-modal-content::-webkit-scrollbar {
    display: none;
}

.notification_modal {
    max-width: 400px;
    width: 400px;
}

.notification_modal .notification_card_header_wrapper_div {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.notification_modal .notification_card_header_wrapper_div .notification_header_title {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.notification_modal .common_text_style {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: #232337;

}

.notification_modal .notification_tabs .archieved_btn {
    font-size: 12px;
    font-weight: 500;
    color: #5A81FA;
    line-height: 14.06px;
    background: #FFFFFF;
    padding: 12px;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
}

.notification_modal .ant-tabs-top>.ant-tabs-nav::before {
    border-bottom: none;
}

.notification_modal .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
    opacity: 0;
}

/* margin between tabs defaoult to 0 */
.notification_modal .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 0px;
}

.notification_modal .ant-tabs .ant-tabs-tab {
    padding: 4px 10px;
}

.notification_modal .notification_tabs {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.notification_modal .listing_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12px;
    gap: 16px;
    background: #F1F5FF;
    border-bottom: 1px solid #727B9A33;
}

.notification_modal .listing_card .ellipse_unread {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #5A81FA;

}

.notification_modal .listing_card .listing_item_body_content {
    display: flex;
    gap: 10px;
}

.notification_modal .listing_item_body_content .icon {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #E6EEFD;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.notification_modal .listing_item_body_content .item_content {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.notification_modal .item_content .text_content {
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    color: #232337;
    margin: 0;
}

.notification_modal .item_content .time_text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    color: #9494AE;
    margin: 0;
}

.notification_modal .approval_btn_parent {
    display: flex;
    gap: 6px;
    align-items: center;
}

.notification_modal .approval_btn_parent .approve_btn {
    padding: 6px 14px;
    border-radius: 4px;
    border: none;
    box-shadow: 0px 2px 12px 0px #3F4BFF66;
    background: #5A81FA;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 19.07px;
    font-weight: 600;
}

.notification_modal .approval_btn_parent .reject_btn {
    padding: 6px 14px;
    border-radius: 4px;
    border: none;
    box-shadow: 0px 2px 12px 0px #3F4BFF66;
    font-size: 14px;
    line-height: 19.07px;
    font-weight: 600;
    border: 1px solid #5A81FA;
    color: #5A81FA;

}